<template>
  <div>
    <form-body-info />
    <packed-tables />
    <form-actions />
  </div>
</template>
<script>

// eslint-disable-next-line import/no-cycle
import { getProps } from '@core/utils/utils'
import store from '@/store'
import packModule from '@/store/main/warehouse/pack'
import { onUnmounted } from '@vue/composition-api'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import config from '../config'
import PackedTables from '../components/packed-tables/index.vue'
import FormActions from '../components/FormActions.vue'
import FormBodyInfo from '../components/FormBodyInfo.vue'

export default {
  name: 'PackCreate',
  components: {
    FormBodyInfo,
    PackedTables,
    FormActions,
  },
  data() {
    return {
      params: {
        id: this.$route.params.id,
        orderType: this.orderPropsByType(this.$route.params.orderType),
      },
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const orderPropsByType = this.params.orderType
      if (!orderPropsByType) return

      this.$store.dispatch(`${this.MODULE_NAME}/getOrderItem`, {
        id: this.params.id,
        orderEndpoint: orderPropsByType.urlAction,
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
    } = config()

    const { orderPropsByType } = mainOrderConfig()

    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, packModule)

    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })

    return {
      MODULE_NAME,
      getProps,
      orderPropsByType,
    }
  },
}
</script>

<style lang="scss">
.packScan {
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  width: 100%;
  padding: 7px 12px;

  &:focus-visible {
    outline: none;
  }
}

.header-columns {
  font-weight: bold;
  font-size: 16px;
  color: rgba(100, 100, 100, 1);
}

.header {
  display: flex;
  gap: 12px;
  padding: 0 12px;
}

.wrapper {
  padding: 12px;
}

.rowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.scannedContainers {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.containerWrapper {
  display: flex;
  align-items: center;
}

.bodyContainer {
  display: flex;
  flex-direction: column;
  padding: 7px 12px;
  border: 1px solid rgba(221, 227, 231, 1);
  background: rgba(0, 177, 57, 0.05);
  border-radius: 5px;
}

.container {
  background: transparent !important;
  border: none !important;
}

.mainInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  background: rgba(0, 177, 57, 0.15);
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px;
  padding: 7px 12px;

  & p {
    margin: 0;
  }
}

.icon-wrapper {
  padding: 5px 8px;
  background: rgba(243, 243, 243, 1);
  border: 1px solid rgba(221, 227, 231, 1);
  border-radius: 5px;
  cursor: pointer;
  width: min-content;
  & > .feather {
    width: unset;
    height: unset;
  }

  &.active {
    background: #646464;

    svg {
      color: white;
    }
  }
}

</style>
