<template>
  <div
    id="assetContainers"
    class="bg-white rounded w-50"
  >
    <h1 class="font-weight-bolder font-medium-5 m-1">
      {{ 'Containers' }}
    </h1>

    <div v-show="openContainerItem">
      <feather-icon
        icon="LWarningIconBlue"
        class="ml-1"
        size="18"
      />
      <span>
        This container is <b>{{ getValueFromGivenObjectByKey(openContainerItem,'product.sku') }} - {{ getValueFromGivenObjectByKey(openContainerItem,'product.name') }}</b> opened.
      </span>
    </div>
    <div class="scanned-containers">
      <div class="wrapper">
        <div class="header">
          <p
            v-for="header in packItemTableColumns"
            :key="header.key"
            :style="header.thStyle"
            class="header-columns"
          >
            {{ header.label }}
          </p>
        </div>
        <div
          class="scannedContainers"
        >
          <div
            v-for="(item, containerIndex) in orderItems.containers"
            :key="containerIndex"
            class="bodyContainer"
          >
            <div class="containerWrapper">
              <div class="mainInfo container">
                <div
                  style="width: 4%"
                >
                  <state-of-asset
                    v-if="item.type_id !== 3"
                    :data="item"
                    :with-label="false"
                    :styles="{ color: '#00B139', width: '18px', height: '18px' }"
                  />
                  <div
                    v-else
                  >
                    <feather-icon
                      :icon="Object.hasOwn(item,'isContainerOpen') && item.isContainerOpen ? 'LPickIcon' : 'LBoxIcon'"
                      size="18"
                      color="#00B139"
                    />
                  </div>
                </div>
                <div
                  style="width: 14%"
                  class="d-flex align-items-center justify-content-between"
                >
                  <p>
                    {{ getValueFromGivenObjectByKey(item, 'asset_id') }}
                  </p>
                  <div
                    v-if="item.type_id === 3"
                    class="icon-wrapper active"
                  >
                    <feather-icon
                      icon="LBoxIcon"
                      size="14"
                    />
                  </div>
                </div>
                <p
                  style="width: 26%"
                >
                  {{ getValueFromGivenObjectByKey(item, 'product.sku') }}
                </p>
                <p
                  style="width: 50%"
                >
                  {{ getValueFromGivenObjectByKey(item, 'product.name') }}
                </p>
              </div>
              <div
                class="icon-wrapper"
                @click="clearContainer(item.id)"
              >
                <feather-icon
                  icon="LTrashIcon"
                  size="12"
                />
              </div>
            </div>
            <div class="rowAssetItems">
              <div
                v-for="(product, index) in item.children"
                :key="index"
                class="rowContainer"
              >
                <div class="mainInfo">
                  <div
                    style="width: 4%"
                  >
                    <state-of-asset
                      :data="product"
                      :with-label="false"
                      :styles="{ color: '#00B139', width: '18px', height: '18px' }"
                    />
                  </div>
                  <p
                    style="width: 14%"
                  >
                    <sub-rental-assets :item="product" />
                  </p>
                  <p
                    style="width: 25%"
                  >
                    {{ getValueFromGivenObjectByKey(product, 'product.sku') }}
                  </p>
                  <p
                    style="width: 50%"
                  >
                    {{ getValueFromGivenObjectByKey(product, 'product.name') }}
                  </p>
                </div>
                <div
                  :class="`icon-wrapper ${product.pack_at ? 'disabledRemoveIcon' : ''}` "
                  @click="!product.pack_at && removeAsset({ containerId: item.id, assetId: product.id })"
                >
                  <feather-icon
                    icon="LClearIcon"
                    size="12"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <scan
        class-name="packScan"
        style="padding:0 12px 12px"
        input-placeholder="Scan a container..."
        @getValue="handleScan"
      />
      <confirm-modal
        ref="confirmRemoveContainerRef"
        :container-item="containerItemForModal"
        @canceled="canceled"
        @deleted="deleted"
      />
    </div>
  </div>
</template>

<script>
import Scan from '@/views/components/Scan/Scan.vue'
import * as _ from 'lodash'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { assetStates } from '@/enum/asset-statuses'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import SubRentalAssets from '@/views/main/warehouse/main-order/components/sub-rental-asset/SubRentalAsset.vue'
import ConfirmModal from './ConfirmModal.vue'
import config from '../../config'

export default {
  name: 'Containers',
  components: {
    StateOfAsset,
    Scan,
    ConfirmModal,
    SubRentalAssets,
  },
  data() {
    return {
      containerItemForModal: {},
      openContainerItem: null,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    orderItems() {
      return this.$store.state[this.MODULE_NAME].orderItems
    },
  },
  methods: {
    canceled() {
      this.containerItemForModal = {}
    },
    deleted(id) {
      const selectedContainer = _.find(this.orderItems.containers, { id })
      if (!selectedContainer) return

      const changedSomeStates = {
        status: assetStates.PICKED.code.toString(),
        pack_at: null,
        parent_id: null,
      }

      const isContainer = selectedContainer.type_id === 3
      if (!isContainer) {
        this.orderItem.order_items = this.orderItem?.order_items.map(item => ({ ...item, pack_at: null }))
      }
      this.openContainerItem = this.openContainerItem?.id === id ? null : this.openContainerItem
      const updatedAssets = isContainer
        ? _.concat(this.orderItems.assets, selectedContainer.children.map(item => ({ ...item, ...changedSomeStates })))
        : _.concat(this.orderItems.assets, { ...selectedContainer, ...changedSomeStates })

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_ASSETS`, updatedAssets)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, _.filter(this.orderItems.containers, item => item.id !== id))
      this.canceled()
    },
    clearContainer(containerId) {
      const selectedContainer = _.find(this.orderItems.containers, { id: containerId })
      if (!selectedContainer) return

      if (selectedContainer.status === assetStates.PACKED.code.toString()) {
        this.containerItemForModal = selectedContainer
        this.$refs.confirmRemoveContainerRef.openModal()
      } else {
        const isContainer = selectedContainer.type_id === 3
        this.openContainerItem = isContainer ? null : this.openContainerItem
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_ASSETS`, _.concat(this.orderItems.assets, isContainer ? selectedContainer.children : selectedContainer))
        this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, _.filter(this.orderItems.containers, item => item.id !== containerId))
      }
    },
    removeAsset({ containerId, assetId }) {
      const selectedContainer = _.find(this.orderItems.containers, { id: containerId })
      const selectedProduct = _.find(selectedContainer?.children, { id: assetId })
      if (!selectedProduct || !selectedContainer) return

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_ASSETS`, [...this.orderItems.assets, selectedProduct])
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, this.orderItems.containers.map(item => (item.id === containerId ? {
        ...item,
        children: item.children.filter(product => product.id !== assetId),
      } : item)))
    },
    handleScan(value) {
      const trimmedValue = value.toString().trim()
      if (!trimmedValue) return
      const scannedProduct = _.find(this.orderItems.assets, item => item.asset_id === Number(trimmedValue) || item.inventory_item?.serial_number === trimmedValue)
      if (!scannedProduct) {
        this.scannedForContainer(trimmedValue)
        return
      }

      if (scannedProduct.status.toString() !== assetStates.PICKED.code.toString()) return
      const openedContainers = _.find(this.orderItems.containers, { isContainerOpen: true })
      const updatedContainers = openedContainers
        ? this.orderItems.containers.map(item => (item.id === openedContainers.id ? {
          ...item,
          children: [...item.children, scannedProduct],
        } : item))
        : [...this.orderItems.containers, scannedProduct]

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, updatedContainers)
      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_ASSETS`, _.remove(this.orderItems.assets, i => i.id !== scannedProduct.id))
    },
    scannedForContainer(val) {
      const trimmedValue = val.trim()
      const scannedProduct = _.find(this.orderItems.containers, { product: { sku: trimmedValue } })
      if (scannedProduct) {
        this.openContainerById(scannedProduct.id)
      } else {
        this.$store.dispatch(`${this.MODULE_NAME}/searchContainerBySku`, {
          search: trimmedValue,
        }).then(res => {
          const { data } = res.data.data
          const newContainer = _.find(data, { type_id: 3, sku: trimmedValue })
          if (newContainer) {
            const newContainerItem = {
              ...newContainer,
              asset_id: newContainer.id,
              product: {
                name: newContainer.name,
                sku: newContainer.sku,
              },
              children: [],
            }
            this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, _.concat(this.orderItems.containers, newContainerItem))
            this.openContainerById(newContainerItem.id)
          }
        }).catch(err => {
          this.errorNotification(this, err)
        })
      }
    },
    openContainerById(id) {
      const container = _.find(this.orderItems.containers, { id, type_id: 3 })
      if (!container) return

      this.$store.commit(`${this.MODULE_NAME}/SET_ORDER_ITEM_CONTAINERS`, _.map(this.orderItems.containers, item => (item.id === id ? {
        ...item,
        isContainerOpen: !item?.isContainerOpen,
      } : { ...item, isContainerOpen: false })))

      this.openContainerItem = container?.isContainerOpen ? null : container
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    return {
      MODULE_NAME,
      getValueFromGivenObjectByKey,
      packItemTableColumns: [
        {
          key: 'state',
          label: 'State',
          thStyle: { width: '7%' },
        },
        {
          key: 'id',
          label: 'Asset Id',
          thStyle: { width: '11%' },
        },
        {
          key: 'sku',
          label: 'Sku',
          thStyle: { width: '23%' },
        },
        {
          key: 'name',
          label: 'Name',
          thStyle: { width: '50%' },
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.scanInput {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

.disabledRemoveIcon {
  cursor: not-allowed;
  opacity: 0.5;
}

.rowAssetItems{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
