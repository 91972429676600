<template>
  <b-modal
    ref="bs-confirm-modal"
    centered
    hide-header
    hide-footer
    modal-class="bs-warning-modal"
  >
    <div class="text-center py-1">
      <h4 class="font-weight-bolder">
        Are you sure you want to delete this products?
      </h4>

      <h5 class="font-weight-bolder pt-1">
        {{ `${getValueFromGivenObjectByKey(containerItem,'asset_id')} — ${getValueFromGivenObjectByKey(containerItem,'product.sku')} — ${getValueFromGivenObjectByKey(containerItem,'product.name')}` }}
      </h5>
      <b-list-group v-if="containerItem.children && containerItem.children.length">
        <b-list-group-item
          v-for="(item,index) in containerItem.children"
          :key="index"
        >
          <div class="d-flex align-center">
            <state-of-asset
              :data="item"
              :with-label="false"
              :styles="{ color: '#00B139', width: '18px', height: '18px',marginRight: '10px' }"
            />
            <p class="m-0">
              {{ `${getValueFromGivenObjectByKey(item,'asset_id')} — ${getValueFromGivenObjectByKey(item,'id')} — ${getValueFromGivenObjectByKey(item,'product.name')}` }}
            </p>
          </div>
        </b-list-group-item>
      </b-list-group>

      <p class="pt-1">
        Please make sure, and then all items status in this product will be picked status.
      </p>

      <div class="d-flex align-center justify-content-around">
        <b-button
          variant="success"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading"
          @click="cancel"
        >
          <span>{{ 'Cancel' }}</span>
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="danger"
        >
          <b-button
            variant="danger"
            class="font-medium-1 font-weight-bold px-3"
            :disabled="isLoading"
            @click="deleted"
          >
            <span>{{ 'Ok' }}</span>
          </b-button>
        </b-overlay>
      </div>

    </div>
  </b-modal>
</template>

<script >

import {
  BButton, BListGroup, BListGroupItem, BOverlay,
} from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import config from '../../config'

export default {
  name: 'ConfirmModal',
  components: {
    StateOfAsset,
    BOverlay,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  props: {
    containerItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      orderType: this.$route.params.orderType,
      isLoading: false,
    }
  },
  methods: {
    getValueFromGivenObjectByKey,
    hideModal() {
      this.$refs['bs-confirm-modal'].hide()
    },
    openModal() {
      this.$refs['bs-confirm-modal'].show()
    },
    cancel() {
      this.$emit('canceled')
      this.hideModal()
    },
    deleted() {
      const orderPropsByType = this.orderPropsByType(this.orderType)
      if (!orderPropsByType) return

      const isContainer = this.containerItem.type_id === 3

      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/${isContainer ? 'removeContainer' : 'removeAsset'}`, {
        body: {
          order_id: this.id,
          [isContainer ? 'container_item_id' : 'order_product_id']: this.containerItem.id,
        },
        orderEndpoint: orderPropsByType.urlAction,
      }).then(() => {
        this.$bvToast.toast('Product deleted successfully', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
        this.$emit('deleted', this.containerItem.id)
        this.hideModal()
      }).catch(err => {
        this.errorNotification(this, err)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
  setup() {
    const { MODULE_NAME } = config()

    const { orderPropsByType } = mainOrderConfig()

    return {
      MODULE_NAME,
      orderPropsByType,
    }
  },
}
</script>
<style lang="scss">
</style>
