<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="backToList"
        >
          {{ $t('Back to List') }}
        </b-button>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          v-if="ruleForHidingButtons()"
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading || !isEmptyAssets"
          @click="printItems('isPackList')"
        >
          <feather-icon icon="LPrintIcon" />
          {{ $t('Print Pack List') }}
        </b-button>
        <b-button
          v-if="ruleForHidingButtons()"
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading || !isEmptyAssets"
          @click="printItems('isLabels')"
        >
          <feather-icon icon="LPrintIcon" />
          {{ $t('Print Labels') }}
          <Printer>
            <div
              id="isLabels"
              hidden
            >
              <print-container />
            </div>
          </Printer>
        </b-button>
        <b-button
          variant="success"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading || !allOrderItemsPacked"
          @click="dispatchOrder"
        >
          <feather-icon icon="LTruckIcon" />
          {{ $t('Dispatch') }}
        </b-button>
      </div>
    </div>

  </portal>
</template>
<script>

import { getProps } from '@core/utils/utils'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import { BButton } from 'bootstrap-vue'
import PrintContainer from '@/views/main/warehouse/view/pack/components/packed-tables/PrintContainer.vue'
import Printer from '@/views/components/Printer/Printer.vue'
import { printContent } from '@/views/main/orders/components/helpers'
import config from '../config'

export default {
  name: 'FormActions',
  components: {
    Printer,
    PrintContainer,
    BButton,
  },
  data() {
    return {
      isLoading: false,
      params: {
        id: this.$route.params.id,
        orderType: this.orderPropsByType(this.$route.params.orderType),
        orderTypeName: this.$route.params.orderType,
      },
      printed: {
        isPackList: false,
        isLabels: false,
      },
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    containers() {
      return this.$store.state[this.MODULE_NAME].orderItems.containers
    },
    assets() {
      return this.$store.state[this.MODULE_NAME].orderItems.assets
    },
    allOrderItemsPacked() {
      return Array.isArray(this.orderItem?.order_items) && this.orderItem?.order_items?.every(item => item.pack_at)
    },
    isEmptyAssets() {
      return this.assets.length === 0
    },
  },
  methods: {
    ruleForHidingButtons() {
      // This is for hiding buttons for DS orders
      return this.params.orderTypeName !== this.ORDER_TYPES_KV.DS
    },
    printItems(type) {
      this.printed[type] = true
      if (type === 'isPackList') {
        printContent('assetContainers')
      } else {
        this.$htmlToPaper(type)
      }
      if (this.printed.isPackList && this.printed.isLabels) {
        this.packItems()
      }
    },
    packItems() {
      if (!this.isEmptyAssets || this.allOrderItemsPacked) return

      const payload = {
        orderEndpoint: this.params.orderType.urlAction,
        body: {
          order_id: this.params.id,
          items: this.containers.map(container => ({
            container_id: container.type_id === 3 ? container.id : null,
            order_product_ids: container?.children?.length ? container.children.map(item => item.id) : container.type_id !== 3 ? [container.id] : [],
          })),
        },
      }

      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/packOrderItems`, payload).then(() => {
        this.$bvToast.toast('Pack date and time overpasses the Dispatch date and time', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(err => {
        this.errorNotification(this, err)
      }).finally(() => {
        this.isLoading = false
        this.printed = {
          isPackList: false,
          isLabels: false,
        }
      })
    },

    dispatchOrder() {
      const body = {
        orderEndpoint: this.params.orderType.urlAction,
        body: {
          order_id: this.params.id,
          warehouse_notes: this.orderItem.warehouse_notes,
        },
      }

      this.isLoading = true
      this.$store.dispatch(`${this.MODULE_NAME}/dispatchOrder`, body).then(() => {
        // this.$bvToast.toast('Product deleted successfully', {
        //   title: 'Success',
        //   variant: 'success',
        //   solid: true,
        // })
        this.$router.push({ name: 'dispatch-order', params: { id: this.params.id } })
      }).catch(err => {
        this.errorNotification(this, err)
      }).finally(() => {
        this.isLoading = false
      })
    },
    backToList() {
      this.$router.go(-1)
    },
  },
  setup() {
    const {
      MODULE_NAME,
    } = config()

    const { orderPropsByType, ORDER_TYPES_KV } = mainOrderConfig()

    return {
      MODULE_NAME,
      getProps,
      ORDER_TYPES_KV,
      orderPropsByType,
    }
  },
}
</script>

<style lang="scss">
</style>
