<template>
  <div>
    <portal to="body-top">
      <div>
        <h3 class="text-center font-weight-bolder mb-2">
          {{ $t(`Fulfillment ${(parentId && children_suffix) ? parentId + "-" + children_suffix : ''} for Order`) }} {{ `${params.orderType}-${params.id}` }}
        </h3>
      </div>
    </portal>
    <quote-status-bar
      class="mb-1"
      :item="quoteStatusItem"
      :quote-status-bar="quoteStatusBarItems"
    />
    <order-exception-status
      :item="orderItem"
      :for-fulfillment-order="params.orderType"
      :module-name="MODULE_NAME"
    />
    <div>
      <b-row class="mt-1">
        <b-col cols="6">
          <service-order-dispatch
            v-if="params.orderType === ORDER_TYPES_KV.SM"
            :order-item="orderItem"
          />
          <transfer-dispatch
            v-if="params.orderType === ORDER_TYPES_KV.TO"
            :order-item="orderItem"
          />
          <rental-sales-dispatch
            v-if="params.orderType === ORDER_TYPES_KV.HO || params.orderType === ORDER_TYPES_KV.SO || params.orderType === ORDER_TYPES_KV.RO || params.orderType === ORDER_TYPES_KV.DS"
            :order-item="orderItem"
          />
          <sub-rental-dispatch
            v-if="params.orderType === ORDER_TYPES_KV.SR"
            :order-item="orderItem"
          />
        </b-col>
        <b-col cols="6">
          <service-order-return
            v-if="params.orderType === ORDER_TYPES_KV.SM"
            :order-item="orderItem"
          />
          <transfer-return
            v-if="params.orderType === ORDER_TYPES_KV.TO"
            :order-item="orderItem"
          />
          <rental-sales-return
            v-if="params.orderType === ORDER_TYPES_KV.HO || params.orderType === ORDER_TYPES_KV.SO || params.orderType === ORDER_TYPES_KV.RO"
            :order-item="orderItem"
          />
          <sub-rental-return
            v-if="params.orderType === ORDER_TYPES_KV.SR"
            :order-item="orderItem"
          />
        </b-col>
      </b-row>
      <service-order-information
        v-if="params.orderType === ORDER_TYPES_KV.SM || params.orderType === ORDER_TYPES_KV.TO"
        class="mt-1"
        :order-item="orderItem"
      />
      <rental-sales-order-information
        v-if="params.orderType === ORDER_TYPES_KV.HO || params.orderType === ORDER_TYPES_KV.SO || params.orderType === ORDER_TYPES_KV.RO || params.orderType === ORDER_TYPES_KV.DS"
        class="mt-1"
        :order-item="orderItem"
      />
      <sub-rental-order-information
        v-if="params.orderType === ORDER_TYPES_KV.SR"
        class="mt-1"
        :order-item="orderItem"
      />
      <payment-modal
        v-if="params.orderType === ORDER_TYPES_KV.DS"
        ref="paymentModal"
      />
    </div>
  </div>
</template>
<script>

import { BCol, BRow } from 'bootstrap-vue'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import ServiceOrderInformation
from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderInformation.vue'
import ServiceOrderDispatch from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderDispatch.vue'
import ServiceOrderReturn from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderReturn.vue'
import RentalSalesDispatch from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesDispatch.vue'
import TransferDispatch from '@/views/main/warehouse/view/pick/transfer/components/TransferDispatch.vue'
import RentalSalesReturn from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesReturn.vue'
import RentalSalesOrderInformation
from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesOrderInformation.vue'
import OrderExceptionStatus
from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'
import TransferReturn from '@/views/main/warehouse/view/pick/transfer/components/TransferReturn.vue'
import PaymentModal from '@/views/main/warehouse/view/pack/components/packed-tables/PaymentModal.vue'
import SubRentalDispatch from '@/views/main/warehouse/view/pick/sub-rental/components/SubRentalDispatch.vue'
import SubRentalReturn from '@/views/main/warehouse/view/pick/sub-rental/components/SubRentalReturn.vue'
import SubRentalOrderInformation from '@/views/main/warehouse/view/pick/sub-rental/components/SubRentalOrderInformation.vue'
import config from '../config'
import warehouseConfig from '../../../config'

export default {
  name: 'FormBodyInfo',
  components: {
    TransferReturn,
    OrderExceptionStatus,
    RentalSalesOrderInformation,
    RentalSalesReturn,
    TransferDispatch,
    RentalSalesDispatch,
    ServiceOrderDispatch,
    ServiceOrderInformation,
    ServiceOrderReturn,
    QuoteStatusBar,
    PaymentModal,
    SubRentalDispatch,
    SubRentalReturn,
    SubRentalOrderInformation,
    BCol,
    BRow,
  },
  data() {
    return {
      params: {
        id: this.$route.params.id,
        orderType: this.$route.params.orderType,
      },
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    parentId() {
      return this.orderItem?.parent_id
    },
    children_suffix() {
      return this.orderItem?.children_suffix
    },
    quoteStatusItem() {
      return this.orderItem && {
        state: this.orderItem.fulfillment_state,
        status: this.orderItem.fulfillment_status,
      }
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.orderItem.states)
    },
  },
  mounted() {
    this.$refs.paymentModal?.showModal()
  },
  setup() {
    const {
      MODULE_NAME,
    } = config()

    const { quoteStatusItemsMerge } = warehouseConfig()

    const { ORDER_TYPES_KV } = mainOrderConfig()

    return {
      quoteStatusItemsMerge,
      MODULE_NAME,
      getProps,
      ORDER_TYPES_KV,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>

<style lang="scss">
</style>
