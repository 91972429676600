<template>
  <div class="m-1 pl-4 pr-4">
    <div
      v-for="(item,index) in container"
      :key="index"
    >
      <div class="d-flex align-items-center justify-content-around mb-1">
        <img
          :src="ErboLogo"
          alt="ErboLogo"
        >
        <h4 class="text-center font-weight-bolder mb-1">
          {{ `${getValueFromGivenObjectByKey(params,'orderType')}-${getValueFromGivenObjectByKey(params,'id')}` }}
        </h4>
      </div>
      <barcode
        class="text-center"
        :value="item.type_id === 3 ? item.product.sku ? item.product.sku : item.id : item.inventory_item.serial_number ? item.inventory_item.serial_number : item.asset_id"
        v-bind="barcodeOptions"
      />
      <div>
        <h4>
          Customer: <b>{{ getValueFromGivenObjectByKey(orderItem, 'customer.cust_name_dba', '—') }}</b>
        </h4>
        <h4>
          Dispatch: <b>{{ orderItem.dispatch_method && orderItem.dispatch_method.created_at ? format(new Date(orderItem.dispatch_method.created_at),'MMMM d, yyyy, hh:mm aaa') : '—' }}</b>
        </h4>
        <h4>
          Return: <b>{{ orderItem.return_method && orderItem.return_method.created_at ? format(new Date(orderItem.return_method.created_at),'MMMM d, yyyy, hh:mm aaa') : '—' }}</b>
        </h4>
        <h4>
          Contents: <b>{{ item.children ? item.children.length : 0 }}</b>
        </h4>
      </div>
      <h4 class="font-weight-bolder text-center">
        Container/item {{ index +1 }} of {{ container.length }}
      </h4>
    </div>
  </div>
</template>
<script>

import VueBarcode from 'vue-barcode'
import ErboLogo from '@/assets/images/ico/erbo-logo.svg'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { format } from 'date-fns'
import config from '../../config'

export default {
  name: 'PrintContainer',
  components: { barcode: VueBarcode },
  data() {
    return {
      barcodeOptions: {
        width: 3,
        height: 60,
        displayValue: false,
      },
      params: {
        id: this.$route.params.id,
        orderType: this.$route.params.orderType,
      },
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    container() {
      return this.$store.state[this.MODULE_NAME].orderItems.containers
    },
  },
  methods: { format },
  setup() {
    const {
      MODULE_NAME,
    } = config()

    return {
      MODULE_NAME,
      ErboLogo,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>

<style lang="scss">
</style>
