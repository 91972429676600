import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'pack-orders'

  const warehousePackFields = {
    warehouseNotes: {
      type: TEXTAREA_INPUT,
      label: 'Warehouse Notes',
      placeholder: 'Write notes here...',
      options: {
        cols: '12',
      },
    },
    orderNotes: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes',
      placeholder: 'User 1 @ Sep 10, 2022: Some order notes.',
      options: {
        cols: '12',
      },
    },
  }

  const packItemTableColumns = [
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '10%' },
    },
    {
      key: 'asset_id',
      label: 'Asset Id',
      thStyle: { width: '30%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { width: '50%' },
    },
    {
      key: 'pack_icon',
      label: 'Pack',
      thStyle: { width: '10%' },
    },
  ]

  return {
    warehousePackFields,
    packItemTableColumns,
    MODULE_NAME,
  }
}
