<template>
  <div class="mt-1 d-flex ">
    <Assets />
    <Containers />
  </div>
</template>

<script>
import Assets from './Assets.vue'
import Containers from './Containers.vue'

export default {
  name: 'PackedTables',
  components: {
    Assets,
    Containers,
  },
  setup() {
    return {}
  },
}
</script>
