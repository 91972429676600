<template>
  <b-modal
    id="bs-payment-modal"
    ref="bs-payment-modal"
    centered
    hide-header
    hide-footer
    body-class="bs-warning-modal__body px-2 py-2"
    modal-class="bs-warning-modal"
  >
    <div class="text-center pt-1">
      <h4 class="font-weight-bolder">
        {{ $t('Charge succeeded') }}
      </h4>
      <span>
        {{ $t('Send a copy of the sales order to an email address ?') }}
      </span>
      <l-text-input
        name="mail"
        :field="{
          placeholder: 'Type...'
        }"
      />
      <b-button
        variant="success"
        size="md"
        @click="hideModal"
      > <span>{{ $t('OK') }}</span></b-button>
    </div>
  </b-modal>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  name: 'PaymentModal',
  components: { BButton },
  methods: {
    showModal() {
      this.$bvModal.show('bs-payment-modal')
    },
    hideModal() {
      this.$bvModal.hide('bs-payment-modal')
    },
  },
}
</script>

<style scoped lang="scss">
#bs-warning-modal .modal-dialog {
  max-width: 500px !important;
}
</style>
